import Footer from "./footer";
import Header from "./header";
import { useEffect } from "react";
import WOW from "wowjs";
import Breadcrumbs from "../Section/faqBreadcrumbsSection";

function FAQ() {
  
  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs title="Frequently Asked Questions" />
      <section class="faq-area section">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".2s">
                <div class="cercle">
                  <span>?</span>
                </div>
                <div class="content">
                  <h4 class="heading"> What is the SheValues Platform?</h4>

                  <p class="text">
                  SheValues is an AI-powered talent marketplace that connects women professionals with top opportunities in various industries. Our platform leverages advanced technology to match skilled female talent with companies looking for expertise, fostering diversity and inclusion in the workforce while empowering women to grow and thrive in their careers.

                  </p>
                </div>
              </div>

              <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".4s">
                <div class="cercle">
                  <span>?</span>
                </div>
                <div class="content">
                  <h4 class="heading">How do I sign up on SheValues?</h4>

                  <p class="text">
                  Signing up is simple! Click the "Sign Up" button at the top of the homepage, and follow the instructions to create your account. 

                  </p>
                </div>
              </div>

              <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".4s">
                <div class="cercle">
                  <span>?</span>
                </div>
                <div class="content">
                  <h4 class="heading">
                  Is the SheValues Platform free to use?
                  </h4>

                  <p class="text">
                  SheValues offers both free and premium plans. Basic membership is free, but to unlock advanced features and resources, you can upgrade to a premium plan

                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".2s">
                <div class="cercle">
                  <span>?</span>
                </div>
                <div class="content">
                  <h4 class="heading">How secure is my personal and payment information?
                  </h4>

                  <p class="text">
                  We take privacy seriously at SheValues. Your personal and payment information is protected by industry-standard encryption. For more details, please review our [Privacy Policy].

                  </p>
                </div>
              </div>

              <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".4s">
                <div class="cercle">
                  <span>?</span>
                </div>
                <div class="content">
                  <h4 class="heading">
                  How do I contact SheValues support?

                  </h4>

                  <p class="text">
                  Get in touch with us by clicking the Contact Us page - Someone will answer your queries promptly

                  </p>
                </div>
              </div>

              <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".4s">
                <div class="cercle">
                  <span>?</span>
                </div>
                <div class="content">
                  <h4 class="heading">Can I cancel or change my subscription plan?</h4>

                  <p class="text">
                  Yes, you can cancel or modify your subscription plan anytime. Visit your account settings, and under the "Subscription" tab, you'll find the options to make changes

                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default FAQ;
