import Footer from "./footer";
import Header from "./header";
import { useEffect } from "react";
import WOW from "wowjs";
import Breadcrumbs from "../Section/privacyJobListbreadcrumbsSection";

function PrivacyPolicy() {

  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs title="Privacy Policy" />
      <div class="privacy-policy section">
        <div class="container">
          <div class="row mb-n5">
            <div class="col-lg-10 offset-lg-1 col-12">
              <div class="policy-inner">
                <div class="policy-details-body">
                <h7 class="mb-3">Privacy Policy</h7>
                  <p>This Privacy Policy explains how SheValues.in ("SheValues," "we," "us," or "our") collects, uses, and discloses information about you when you use our website located at SheValues.in (the "Site") and any other online services provided by SheValues that link to this Privacy Policy (collectively, the "Services").</p>

                  <h7 class="mb-3">1. Information We Collect</h7>
                  <p>
                    We collect information you provide directly to us when you use the Services. The types of information we may collect include:
                    <ul>
                      <li>
                        Registration Information: When you register for an account, we collect your name, email address, and other information you provide.
                      </li>
                      <li>
                        Profile Information: We may collect information about you when you create a profile, such as your job title, company name, and other relevant details.
                      </li>
                      <li>Profile Information: We may collect information about you when you create a profile, such as your job title, company name, and other relevant details.</li>
                      <li> Communication Information: When you communicate with us or other users through the Services, we collect information about your communication and any information you choose to provide.</li>
                      <li>Usage Information: We automatically collect certain information about your use of the Services, including your IP address, browser type, operating system, referring URLs, access times, pages viewed, links clicked, and other interactions with the Services.</li>
                      <li>Device Information: We may collect information about the device you use to access the Services, including unique device identifiers, mobile network information, and device attributes.</li>

                    </ul>
                  </p>

                  <h7 class="mb-3">2. How We Use Your Information</h7>

                  <p>
                    We may use the information we collect for various purposes, including to:

                    <ul>
                      <li>  Provide, maintain, and improve the Services;</li>
                      <li> Customize and personalize your experience on the Services;</li>
                      <li> Communicate with you about the Services and respond to your inquiries;</li>
                      <li> Send you important notices, updates, and promotional messages;</li>
                      <li>Monitor and analyze trends, usage, and activities in connection with the Services;</li>
                      <li> Detect, investigate, and prevent fraudulent or unauthorized activity;</li>
                      <li>Comply with legal obligations and enforce our terms and policies.</li>
                    </ul>
                  </p>

                  <h7 class="mb-3">3. Sharing of Information</h7>

                  <p>
                    We may share your information with third parties as follows:
                    <ul>
                      <li>  With service providers, vendors, and other third parties who need access to such information to carry out work on our behalf;</li>
                      <li>With our business partners, affiliates, and other trusted entities for purposes of providing the Services;</li>
                      <li> In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process;</li>
                      <li> If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of SheValues or others;</li>
                      <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>

                    </ul>
                  </p>

                  <h7 class="mb-3">4. Your Choices</h7>
                  <p>You may opt out of receiving promotional communications from us by following the instructions provided in those communications. Please note that even if you opt out, we may still send you non-promotional messages related to your use of the Services.
                  </p>

                  <h7 class="mb-3">5. Data Security
                  </h7>
                  <p>We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
                  </p>

                  <h7 class="mb-3">6. Changes to this Privacy Policy</h7>
                  <p>We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated Privacy Policy on the Site and updating the "Last Updated" date at the top of this Privacy Policy.
                  </p>

                  <h7 class="mb-3">
                    7. Contact Us
                  </h7>
                  <p>If you have any questions about this Privacy Policy, please contact us at contact@shevalues.in. <br />

                    By using the Services, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.
                  </p>




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default PrivacyPolicy;
