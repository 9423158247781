import { useEffect } from "react";

import WOW from "wowjs";

function BreadcrumbsSection(props) {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div class="breadcrumbs overlay">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumbs-content">
              <h1 class="page-title">{props.title}</h1>
              <p>
              This Privacy Policy explains how SheValues.in ("SheValues," "we," "us," or "our") collects, uses, and discloses information about you when you use our website located at SheValues.in

              </p>
            </div>
            <ul class="breadcrumb-nav">
              <li>
                <a href="/home">Home</a>
              </li>
              <li>{props.title}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BreadcrumbsSection;
